<template>
  <div>
    <loader
      v-if="Loadmore"
      object="#9658cc"
      color1="#532a76"
      color2="#d3bae9"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="30"
      name="circular"
    />
    <!-- <this-header /> -->
    <div class="container-sm-lot pl-1 pr-1">
      <div class="top-bxt my-1">
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="$router.go(-1)"
        >

        <h3 class="text text-primary mb-0">
          เซียนแนะนำเพื่อน
        </h3>
      </div>

      <div class="d-flex align-items-center justify-content-start mt-2">
        <h3 class="text mb-0">
          แนะนำเพื่อนรับโบนัส
        </h3>
      </div>

      <div class="card-list p-puple mt-50 border-0 mb-1">
        <div class="d-flex justify-content-between">
          <div>
            <p class="mb-0 text-white">
              รหัสชวนเพื่อน
            </p>

            <h2 class="mb-0 txt-sh">
              {{ userData.invite_code }}
            </h2>

            <small class="mb-0 text-primary" />
          </div>

          <div class="bg-white p-25 rounded">
            <qr-code
              :text="ref"
              size="80"
            />
          </div>
        </div>
      </div>

      <div>
        <small class="text-warning"> ลิงก์ชวนเพื่อน </small>
        <p class="text-primary mb-0">
          {{ ref }}
        </p>

        <button
          class="mb-0 btn-copy w-100 p-75 my-2"
          @click="copyRef"
        >
          <i class="fal fa-copy mr-50" /> คัดลอกลิงก์ชวนเพื่อน
        </button>
      </div>

      <div class="text-center mt-1">
        <span class="bg-body font-medium-3 text-primary p-75">
          คอมมิชชั่น
        </span>
        <hr class="border-top-3 mt-n75">
      </div>

      <div class="row">
        <div class="col-6 p-0">
          <div class="card-list mt-50 border-0 mb-2 p-75 mr-50">
            <div class="d-flex justify-content-between">
              <div>
                <p class="mb-0 text-primary">
                  สมัคร
                </p>

                <h2 class="mb-0 text">
                  {{
                    InviteData.Find.invite_user_total
                      ? InviteData.Find.invite_user_total
                      : 0
                  }}
                  <small class="text-primary">คน</small>
                </h2>

                <small class="mb-0 text-white" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 p-0">
          <div class="card-list mt-50 border-0 p-75 ml-50">
            <div class="d-flex justify-content-between">
              <div>
                <p class="mb-0 text-primary">
                  การสั่งซื้อ
                </p>

                <h2 class="mb-0 text">
                  {{
                    InviteData.Find.invite_buy_count
                      ? InviteData.Find.invite_buy_count
                      : 0
                  }}
                  <small class="text-primary">ชุด</small>
                </h2>

                <small class="mb-0 text-primary" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 p-0">
          <div class="card-list border-0 mb-2 p-75">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <p class="mb-0 text-primary">
                  ค่าคอมมิชชั่นทั้งหมด
                </p>
                <h2 class="mb-0 text">
                  {{
                    InviteData.Find.invite_commission
                      ? Commas(InviteData.Find.invite_commission)
                      : 0
                  }}
                  <small class="text-primary">₩</small>
                </h2>
              </div>

              <button
                class="btn-claim"
                @click="ReceiveCom"
              >
                <img
                  src="/coin-1.png"
                  alt=""
                > รับเงิน
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 p-0">
          <div class="card-list border-0 mb-2 p-75">
            <div>
              <p class="mb-0 text-primary">
                ลูกค้าถูกรางวัล
              </p>
              <h2 class="mb-0 text">
                {{ Commas(RewardFriend) }} <small class="text-primary">₩</small>
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-1">
        <p class="text-warning mb-1">
          ประวัติการชวนเพื่อน
        </p>

        <div
          v-if="InviteData.FindFriend.length === 0"
          class="text-center my-4"
        >
          <img
            src="/icon/friend.png"
            alt="friend"
            height="75"
          >

          <p class="mb-0 text">
            ยังไม่มีประวัติการชวนเพื่อน
          </p>
        </div>

        <div
          v-for="item in InviteData.FindFriend"
          :key="item"
          class="htry-list mb-2"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <small class="mb-0 text-primary"> ยูสเซอร์เมน </small>
              <h4 class="text-primary">
                {{ "XXX" + item.username.slice(0, 3) }}
              </h4>
            </div>

            <h4 class="mb-0 text-primary">
              {{ item.mylotto_count }} ชุด
            </h4>
          </div>
        </div>
      </div>
    </div>

    <ThisFooter />
    <!-- </div> -->
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import ThisHeader from './components/ThisHeader.vue'
import ThisFooter from './components/ThisFooter.vue'

export default {
  name: 'Home',
  components: {
    // ThisHeader,
    ThisFooter,
  },
  data() {
    return {
      Loadmore: false,
      userData: JSON.parse(localStorage.getItem('userData')), // getlocalstorage
      balance: 0,
      ref: '',
      balance_withdraw: 0,
      balance_datecheck: '',
      InviteData: null,
      RewardFriend: 0,
    }
  },
  created() {
    if (!this.userData) {
      this.$router.push({ name: 'home' })
    }
  },
  mounted() {
    this.GetInviteDetail()
    this.GetRewardLog()
    this.GetAgent()
  },
  methods: {
    GetRewardLog() {
      this.$http
        .get('lotto-suvarn/Invite-Friend/GetRewardFriend')
        .then(ress => {
          // console.log(ress.data)
          this.RewardFriend = ress.data.reduce((b, a) => b + a.reward_prize, 0)
        })
        .catch(err => {
          // console.log(err.response.data.message)
          this.SwalError(err.response.data.message)
        })
    },
    ReceiveCom() {
      this.$http
        .get('lotto-suvarn/Invite-Friend/Receive')
        .then(ress => {
          // console.log(ress.data)
          this.GetInviteDetail()
          this.getBalance()
          this.Success(ress.data.message)
        })
        .catch(err => {
          // console.log(err.response.data.message)
          this.SwalError(err.response.data.message)
        })
    },
    GetAgent() {
      this.$http
        .get('lotto-suvarn/agent/show')
        .then(ress => {
          this.agent = ress.data
          this.ref = `${ress.data.site}/register?ref=${this.userData.invite_code}`
        })
        .catch(err => {
          // console.log(err.response.data.message)
          this.SwalError(err.response.data.message)
        })
    },
    GetInviteDetail() {
      this.Loadmore = true
      this.$http
        .get('lotto-suvarn/Invite-Friend')
        .then(ress => {
          // console.log(ress.data)
          this.InviteData = ress.data
          this.Loadmore = false
          // console.log(this.InviteData)
        })
        .catch(err => {
          // console.log(err.response.data.message)
          this.SwalError(err.response.data.message)
        })
    },
    copyRef() {
      // copy ref
      const el = document.createElement('textarea')
      el.value = this.ref
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          icon: 'CoffeeIcon',
          variant: 'success',
          text: 'คัดลอกลิงก์ชวนเพื่อนสำเร็จ',
        },
      })
    },
    logout() {
      localStorage.removeItem('userData')
      this.$router.push({ name: 'home' })
      window.location.reload()
    },
    getBalance() {
      this.$http.get('balance/getbalance').then(ress => {
        // console.log(ress.data)
        this.balance = ress.data
        this.balance_datecheck = moment()
          .tz('Asia/Bangkok')
          .format('YYYY-MM-DD HH:mm:ss')
        localStorage.setItem('balance', JSON.stringify(ress.data.balance))
      })
    },
    Commas(x) {
      if (!x) {
        return 0
      }
      if (x % 1 !== 0) {
        return Number(x)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ลงตะกร้าสำเร็จ</h3>',
        text: mes,
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        timer: 2000,
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ขออภัยค่ะ !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped></style>
